export default [
    'BCA',
    'MANDIRI',
    'BNI',
    'BRI',
    'BII',
    'CIMB',
    'HSBC',
    'PERMATA',
    'BTN',
    'PANIN',
    'DANAMON'
];
