var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register" }, [
    _vm.curStep === _vm.StepTypes.REGISTER_ONE
      ? _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.TabList, function (tab) {
            return _c(
              "button",
              {
                key: tab.type,
                class: { active: tab.type === _vm.lenderType },
                on: {
                  click: function ($event) {
                    return _vm.chooseTab(tab.type)
                  },
                },
              },
              [_vm._v(_vm._s(tab.title))]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _vm.curStep === _vm.StepTypes.REGISTER_ONE
        ? _c(
            "form",
            {
              staticClass: "form-block",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.lenderType === _vm.StepTypes.COMPANY
                ? _c("div", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nama Perusahaan")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.companyName,
                            expression: "editing.companyName",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.companyName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "companyName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Tanda Daftar Perusahaan")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.companyId,
                            expression: "editing.companyId",
                          },
                        ],
                        attrs: { type: "tel" },
                        domProps: { value: _vm.editing.companyId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "companyId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Pokok Wajib Pajak")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.companyTaxId,
                            expression: "editing.companyTaxId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.companyTaxId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "companyTaxId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nama Direktur")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualName,
                            expression: "editing.individualName",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.individualName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Identitas Direktur")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualId,
                            expression: "editing.individualId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.individualId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Pokok Wajib Pajak Direktur")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualTaxId,
                            expression: "editing.individualTaxId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.individualTaxId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualTaxId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Ponsel Direktur")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualMobileNumber,
                            expression: "editing.individualMobileNumber",
                          },
                        ],
                        attrs: { type: "tel" },
                        domProps: { value: _vm.editing.individualMobileNumber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualMobileNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _c("div", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nama")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualName,
                            expression: "editing.individualName",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.individualName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Identitas")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualId,
                            expression: "editing.individualId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.individualId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Pokok Wajib Pajak")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualTaxId,
                            expression: "editing.individualTaxId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.editing.individualTaxId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualTaxId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Nomor Ponsel")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editing.individualMobileNumber,
                            expression: "editing.individualMobileNumber",
                          },
                        ],
                        attrs: { type: "tel" },
                        domProps: { value: _vm.editing.individualMobileNumber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editing,
                              "individualMobileNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn-block", on: { click: _vm.nextStep } },
                [_vm._v("Langkah Selanjutnya")]
              ),
            ]
          )
        : _c("div", [
            _c("h3", { staticClass: "contact-title" }, [
              _vm.lenderType === _vm.StepTypes.COMPANY
                ? _c("span", [_vm._v("Pemberi Pinjaman Perusahaan")])
                : _c("span", [_vm._v("Pemberi Pinjaman Perorangan")]),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "form-block",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Nama Kontak")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editing.contactName,
                        expression: "editing.contactName",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.editing.contactName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.editing,
                          "contactName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Nomor Telefon")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editing.contactMobileNumber,
                        expression: "editing.contactMobileNumber",
                      },
                    ],
                    attrs: { type: "tel" },
                    domProps: { value: _vm.editing.contactMobileNumber },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.editing,
                          "contactMobileNumber",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("E-mail")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editing.contactEmail,
                        expression: "editing.contactEmail",
                      },
                    ],
                    attrs: { type: "email" },
                    domProps: { value: _vm.editing.contactEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.editing,
                          "contactEmail",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Bank")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editing.bankType,
                          expression: "editing.bankType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.editing,
                            "bankType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.BankList, function (bank) {
                      return _c("option", { domProps: { value: bank } }, [
                        _vm._v(_vm._s(bank)),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Nomor akun Bank")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editing.bankAccountNo,
                        expression: "editing.bankAccountNo",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.editing.bankAccountNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.editing,
                          "bankAccountNo",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "btn-wrap" }, [
                  _c("p", { staticClass: "center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-back",
                        on: {
                          click: function ($event) {
                            _vm.curStep = _vm.StepTypes.REGISTER_ONE
                          },
                        },
                      },
                      [_vm._v("kembali")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn-block", on: { click: _vm.submit } },
                    [_vm._v("Serahkan")]
                  ),
                ]),
              ]
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }