/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2018-07-14 12:07:03.880
 * @desc generated by yqg-cli@0.2.4
 */

import Resource from 'moneycan/common/resource';

import ValidOptions from './constant/valid-options';

const TIME_INTERVAL = 60;

export default {
    name: 'PreRegister',

    title: 'Daftar',

    data() {
        return {
            KEY: 'EASYCASH_LENDER_AGREEMENT',
            editing: {
                mobileNumber: '',
                verificationCode: ''
            },
            captchaKey: '',
            captcha: '',
            secondCount: 0
        };
    },

    computed: {
        captchaImgUrl() {
            const vm = this;

            return Resource.getCaptchaImageUrl(vm.captchaKey);
        },

        isCounting() {
            const vm = this;

            return vm.secondCount > 0;
        }
    },

    methods: {
        refreshCaptcha() {
            const vm = this;
            Resource.generateCaptcha().then(({body: {body: key}}) => {
                vm.captchaKey = key;
            }).catch(x => x);
        },

        sendVerification() {
            const vm = this;
            const {editing: {mobileNumber}, captcha, captchaKey} = vm;
            if (!captcha || !mobileNumber) {
                vm.$toast.error('Silahkan isi captcha atau nomor telefon');

                return;
            }

            vm.startCountDown();

            const authData = {
                mobileNumber,
                captchaKey,
                captcha,
                verificationPurpose: 'REGISTER'
            };

            Resource.sendVerification(authData).then(() => {
                vm.$toast.success('Kode verifikasi telah berhasil dikirim');
            }).catch(() => {
                vm.$toast.error('Nomor telefon atau captcha salah');
                vm.refreshCaptcha();
                vm.stopCountDown();
            });
        },

        startCountDown() {
            const vm = this;
            vm.secondCount = TIME_INTERVAL;

            vm.intervalId = setInterval(() => {
                vm.secondCount -= 1;

                if (vm.secondCount <= 0) {
                    vm.stopCountDown();
                }
            }, 1e3);
        },

        stopCountDown() {
            const vm = this;
            vm.secondCount = 0;
            if (vm.intervalId) clearInterval(vm.intervalId);
        },

        preRegister() {
            const vm = this;

            if (!vm.$valid.checkOptions(vm.editing, ValidOptions)) return;

            Resource.register(vm.editing).then(({body: {body: {registerId}}}) => {
                vm.$emit('next', registerId);
            });
        }
    },

    mounted() {
        const vm = this;
        vm.refreshCaptcha();
    },

    destroyed() {
        const vm = this;
        vm.stopCountDown();
    }
};
