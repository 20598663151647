var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pre-register" }, [
    _c(
      "form",
      {
        staticClass: "form-block",
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Nomor Ponsel")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.editing.mobileNumber,
                expression: "editing.mobileNumber",
              },
            ],
            attrs: { type: "tel" },
            domProps: { value: _vm.editing.mobileNumber },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.editing, "mobileNumber", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Captcha")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-block" }, [
            _c("div", { staticClass: "left" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.captcha,
                    expression: "captcha",
                  },
                ],
                attrs: { type: "tel" },
                domProps: { value: _vm.captcha },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.captcha = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("img", {
                staticClass: "captcha-img",
                attrs: { src: _vm.captchaImgUrl, alt: "Captcha" },
                on: { click: _vm.refreshCaptcha },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Kode SMS")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-block" }, [
            _c("div", { staticClass: "left" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editing.verificationCode,
                    expression: "editing.verificationCode",
                  },
                ],
                attrs: { type: "tel" },
                domProps: { value: _vm.editing.verificationCode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.editing,
                      "verificationCode",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _vm.isCounting
                ? _c("button", { staticClass: "btn btn-sms" }, [
                    _vm._v(_vm._s(_vm.secondCount)),
                  ])
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-sms",
                      on: { click: _vm.sendVerification },
                    },
                    [_vm._v("Kirim Kode")]
                  ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "btn-wrap" }, [
          _c(
            "div",
            [
              _vm._v("\n                Sudah membaca\n                "),
              _c(
                "router-link",
                {
                  staticClass: "link",
                  attrs: {
                    to: { name: "LenderAgreement", query: { key: _vm.KEY } },
                  },
                },
                [
                  _vm._v(
                    "\n                     “Perjanjian Pemberi Pinjaman dan Penyelengara”\n                "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn-block", on: { click: _vm.preRegister } },
            [_vm._v("Daftar")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }