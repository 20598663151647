import { render, staticRenderFns } from "./pre-register.html?vue&type=template&id=4abde46a&scoped=true&"
import script from "./pre-register.js?vue&type=script&lang=js&"
export * from "./pre-register.js?vue&type=script&lang=js&"
import style0 from "./pre-register.scss?vue&type=style&index=0&id=4abde46a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4abde46a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4abde46a')) {
      api.createRecord('4abde46a', component.options)
    } else {
      api.reload('4abde46a', component.options)
    }
    module.hot.accept("./pre-register.html?vue&type=template&id=4abde46a&scoped=true&", function () {
      api.rerender('4abde46a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/easycash-indo/webview/lender/sign-up/component/pre-register/pre-register.vue"
export default component.exports