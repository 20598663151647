/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2018-07-14 12:10:01.506
 * @desc generated by yqg-cli@0.2.4
 */

import Resource from 'moneycan/common/resource';

import {StepTypes} from '../../constant/step-conf';
import BankList from './constant/bank-list';
import {CompanyStepOneOptions, IndividualStepOneOptions, StepTwoOptions} from './constant/valid-options';

const TabList = [
    {
        title: 'Perusahaan',
        type: StepTypes.COMPANY
    },
    {
        title: 'Perorangan',
        type: StepTypes.INDIVIDUAL
    }
];

export default {
    name: 'Register',

    title: 'Daftar',

    props: ['registerId'],

    data() {
        return {
            TabList,
            StepTypes,
            BankList,
            lenderType: StepTypes.COMPANY,
            curStep: StepTypes.REGISTER_ONE,
            editing: {}
        };
    },

    methods: {
        chooseTab(type) {
            this.lenderType = type;
        },

        nextStep() {
            const vm = this;
            const {lenderType} = vm;

            if (
                lenderType === StepTypes.COMPANY
                && !vm.$valid.checkOptions(vm.editing, CompanyStepOneOptions)
            ) return;
            if (
                lenderType === StepTypes.INDIVIDUAL && !vm.$valid.checkOptions(vm.editing, IndividualStepOneOptions)
            ) return;

            vm.curStep = StepTypes.REGISTER_TWO;
        },

        submit() {
            const vm = this;
            const {registerId, lenderType, editing} = vm;

            if (!vm.$valid.checkOptions(vm.editing, StepTwoOptions)) return;

            Resource.completeRegisterInfo({
                registerId,
                lenderType,
                ...editing
            }).then(() => {
                vm.$toast.success('Berhasil');
                vm.$emit('next');
            });
        }
    }
};
