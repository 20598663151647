export const CompanyStepOneOptions = [
    {field: 'companyName', required: true},
    {field: 'companyId', required: true},
    {field: 'companyTaxId', required: true},
    {field: 'individualName', required: true},
    {field: 'individualId', required: true},
    {field: 'individualTaxId', required: true},
    {field: 'individualMobileNumber', required: true}
];

export const IndividualStepOneOptions = [
    {field: 'individualName', required: true},
    {field: 'individualId', required: true},
    {field: 'individualTaxId', required: true},
    {field: 'individualMobileNumber', required: true}
];

export const StepTwoOptions = [
    {field: 'contactName', required: true},
    {field: 'contactMobileNumber', required: true},
    {field: 'contactEmail', required: true},
    {field: 'bankType', required: true},
    {field: 'bankAccountNo', required: true}
];

