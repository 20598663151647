var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sign-up" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.curStep === _vm.StepTypes.AGREEMENT
          ? _c("agreement", { on: { next: _vm.agreementSuccess } })
          : _vm.curStep === _vm.StepTypes.PRE_REGISTER
          ? _c("pre-register", { on: { next: _vm.preRegisterSuccess } })
          : _c("register", {
              attrs: { "register-id": _vm.registerId },
              on: { next: _vm.registerSuccess },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }