/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2018-07-14 11:25:11.847
 * @desc generated by yqg-cli@0.2.4
 */

import Agreement from './component/agreement';
import PreRegister from './component/pre-register';
import Register from './component/register';
import {StepTypes} from './constant/step-conf';

export default {
    name: 'SignUp',

    components: {
        Agreement,
        PreRegister,
        Register
    },

    data() {
        return {
            StepTypes,
            curStep: StepTypes.AGREEMENT,
            registerId: null
        };
    },

    methods: {
        agreementSuccess() {
            this.curStep = StepTypes.PRE_REGISTER;
        },

        preRegisterSuccess(registerId) {
            this.registerId = registerId;
            this.curStep = StepTypes.REGISTER;
        },

        registerSuccess() {
            this.$router.back();
        }
    }
};
