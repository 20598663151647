var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agreement" }, [
    _c("h4", { staticClass: "center" }, [
      _vm._v("Pemberitahuan dan Persyaratan Pemberi Pinjaman"),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "agreement-agree" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isAgree,
            expression: "isAgree",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.isAgree)
            ? _vm._i(_vm.isAgree, null) > -1
            : _vm.isAgree,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.isAgree,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.isAgree = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.isAgree = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.isAgree = $$c
            }
          },
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        [
          _vm._v("\n            Sudah membaca\n            "),
          _c(
            "router-link",
            {
              staticClass: "link",
              attrs: {
                to: { name: "LenderAgreement", query: { key: _vm.KEY } },
              },
            },
            [
              _vm._v(
                "\n                 “Perjanjian Pemberi Pinjaman dan Penyelengara”\n            "
              ),
            ]
          ),
          _vm._v(
            "\n            dan sudah mengetahui serta memenuhi persyaratan pemberi pinjam.\n        "
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "btn-wrap" }, [
      _c(
        "button",
        { staticClass: "btn-block disagree", on: { click: _vm.disagree } },
        [_vm._v("Tidak setuju")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn-block",
          class: { disagree: !_vm.isAgree },
          on: { click: _vm.agree },
        },
        [_vm._v("Paham")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("p", [
        _vm._v(
          "PT. Indonesia Fintopia Technology (Di Bawah ini menggunakan Fintopia) adalah badan hukum yang didirikan berdasarkan hukum Republik Indonesia dan di bawah pengawasan Otoritas Jasa Keuangan (OJK) di Indonesia. Fintopia mematuhi Peraturan Otoritas Jasa Keuangan (POJK) Nomor 77 / POJK.01 / 2016 tentang Meminjam Teknologi Informasi Berbasis Uang dengan nomor registrasi XXXXXXXXXX. Oleh karena itu, Fintopia berkomitmen untuk selalu memperhatikan perlindungan konsumen dengan menjunjung tinggi integritas dan kepatuhan terhadap ketentuan hukum di Indonesia. Kami, bersama OJK, terus menjaga kepentingan terbaik pelanggan kami."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Easycash didirikan oleh orang-orang yang telah berpengalaman secara profesional dalam dunia investasi dan pembiayaan, baik\n        sebagai bankir senior, konsultan hukum/advokat, konsultan keuangan maupun akuntan – kami mengerti perlindungan yang investor\n        butuhkan. Pemberian pinjaman yang anda lakukan akan mendapatkan perlindungan yang biasa didapatkan oleh investor profesional.\n        Kami melakukan analisa kredit atas setiap debitur yang melakukan penggalangan pinjaman guna menyaring debitur yang belum\n        siap, tidak layak, atau usaha fiktif serta mencegah adanya informasi material yang menyesatkan atau yang tidak diungkapkan.\n        Dana pinjaman akan kami setorkan kepada debitur setelah debitur menandatangani dokumen-dokumen legal yang ditujukan untuk\n        melindungi pemberi pinjaman."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("1. Pemberi pinjaman dapat berupa Perusahaan atau Perorangan"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "2. Perusahaan harus badan hukum dan tidak terlarang untuk memberikan pinjaman dana"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "3. Individu harus berusia 18-60 tahun, mandiri, bertanggung jawab dan  sanggup mencukupi keperluan pribadi"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "4. Pemberi pinjaman setidaknya perlu memiliki 1 juta USD untuk dapat menjadi pemberi pinjaman di Easycash"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "5. Layanan Pinjam Meminjam Berbasis Teknologi Informasi merupakan kesepakatan perdata antara Pemberi Pinjaman dengan Penerima Pinjaman, sehingga segala risiko yang timbul dari kesepakatan tersebut ditanggung sepenuhnya oleh masing-masing pihak."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "6. Risiko kredit atau gagal bayar ditanggung sepenuhnya oleh Pemberi Pinjaman. Tidak ada lembaga atau otoritas negara yang bertanggung jawab atas risiko gagal bayar ini."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "7. Pemberi Pinjaman yang belum memiliki pengetahuan dan pengalaman pinjam meminjam, disarankan untuk tidak menggunakan layanan ini."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "8. Pengguna harus membaca dan memahami informasi ini sebelum membuat keputusan menjadi Pemberi Pinjaman atau Penerima Pinjaman."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }