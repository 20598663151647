/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2018-07-14 12:02:05.980
 * @desc generated by yqg-cli@0.2.4
 */

export default {
    name: 'Agreement',

    title: 'Pemberitahuan dan Persyaratan',

    data() {
        return {
            KEY: 'EASYCASH_LENDER_AGREEMENT',
            isAgree: false
        };
    },

    methods: {
        agree() {
            const vm = this;

            if (!vm.isAgree) {
                vm.$toast.error('Harap mengkonfimasi persyaratan dan persetujuan dengan mencentang kotak diatas');

                return;
            }

            vm.$emit('next');
        },

        disagree() {
            this.$router.back();
        }
    }
};
